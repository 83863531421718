import React from "react";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  TypographyProps,
} from "@mui/material";

const TermsAndConditions: React.FC<TypographyProps> = (_) => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h6">Bakgrund</Typography>

      <Typography variant="body1">
        Denna personuppgiftspolicy anger regler och riktlinjer kring hur
        personuppgifter hanteras vid användandet av tjänsten.
      </Typography>

      <Typography variant="h6">Syfte</Typography>

      <Typography variant="body1">
        Syftet med policyn är att tillhandahålla information kring hur
        personuppgifter hanteras inom tjänsten.
      </Typography>

      <Typography variant="h6">Definition av personuppgifter</Typography>

      <Typography variant="body1">
        Till begreppet personuppgift hör all information som, antingen direkt
        eller indirekt, kan identifiera en levande fysisk person. Med direkta
        personuppgifter förstås sådant som ensamt kan identifiera en viss person
        medan indirekta personuppgifter kan identifiera en person tillsammans
        med andra personuppgifter
      </Typography>

      <Typography variant="h6">Rätt att få sina uppgifter raderade</Typography>

      <Typography variant="body1">
        Om så önskas, kan hen begära att hens information raderas. Detta göres
        genom att kontakta vårt dataskyddsombud via e-post, info@xenit.se
      </Typography>

      <Typography variant="h6">Behandling av personuppgifter</Typography>

      <Typography variant="body1">
        Följande personuppgifter behandlas inom ramen för tjänsteleveransen
      </Typography>

      <List>
        <ListItem>
          {" "}
          <ListItemText primary="Personnummer"> </ListItemText>{" "}
        </ListItem>
        <ListItem>
          {" "}
          <ListItemText primary="Namn"> </ListItemText>{" "}
        </ListItem>
        <ListItem>
          {" "}
          <ListItemText primary="IP-adress"> </ListItemText>{" "}
        </ListItem>
        <ListItem>
          {" "}
          <ListItemText primary="Eventuella personuppgifter i signaturdata">
            {" "}
          </ListItemText>{" "}
        </ListItem>
      </List>

      <Typography variant="body1">
        Personuppgifterna sparas och behandlas för följande syften:
      </Typography>

      <ListItem>
        {" "}
        <ListItemText primary="Administration av inloggning">
          {" "}
        </ListItemText>{" "}
      </ListItem>
      <ListItem>
        {" "}
        <ListItemText primary="Lagring för att kunna kontrollera inloggning för uppfyllande av rättslig förpliktelse">
          {" "}
        </ListItemText>{" "}
      </ListItem>

      <Typography variant="body1">
        Personuppgifterna sparas i max 12 månader och behandligen sker inom
        företaget, således lämnas inga personuppgifter ut till tredje part.
      </Typography>

      <Typography variant="h6">Säkerhet</Typography>

      <Typography variant="body1">
        Följande säkerhetsåtgärder är standard i tjänsten för hantering av
        personuppgifter
      </Typography>

      <ListItem>
        {" "}
        <ListItemText primary="Roll och behovsbaserad behörighetstilldelning">
          {" "}
        </ListItemText>{" "}
      </ListItem>
      <ListItem>
        {" "}
        <ListItemText primary="Krypterad kommunikation"> </ListItemText>{" "}
      </ListItem>
      <ListItem>
        {" "}
        <ListItemText primary="Krypterade data i vila"> </ListItemText>{" "}
      </ListItem>
      <ListItem>
        {" "}
        <ListItemText primary="Loggning av säkerhetsrelaterade händelser (Auditloggning)">
          {" "}
        </ListItemText>{" "}
      </ListItem>

      <Typography variant="body1">
        Vid frågor som rör tjänsten och dess innehåll ska vårt dataskyddsombud
        kontaktas via e-post, info@xenit.se
      </Typography>
    </Container>
  );
};

export default TermsAndConditions;

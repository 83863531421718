import initializeTracer from "shared/tracer";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import TermsAndConditions from "./TermsAndConditions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "termsandconditions",
    element: <TermsAndConditions />,
  },
]);

initializeTracer()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch(() => {
    ReactDOM.render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

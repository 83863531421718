import React, { MutableRefObject } from "react";

import { Button, CircularProgress, Typography } from "@mui/material";

import BankIdVectorRgb from "shared/images/BankIdVectorRgb";

interface BankIdThisDeviceLoginProps {
  bankIdOpened: boolean;
  loginFailed: boolean;
  setBankIdOpened: (opened: boolean) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  startBankIdLogin: () => Promise<void>;
  bankIdUrl: MutableRefObject<string>;
  userMessage: string;
}

const BankIdThisDeviceLogin: React.FC<BankIdThisDeviceLoginProps> = ({
  bankIdOpened,
  loginFailed,
  setBankIdOpened,
  onBackClick,
  startBankIdLogin,
  bankIdUrl,
  userMessage,
}) => {
  const onOpenBankIdClick = async (
    _event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    await startBankIdLogin();
    setBankIdOpened(true);
    window.location.assign(bankIdUrl.current);
  };

  return (
    <>
      <BankIdVectorRgb
        sx={{
          width: 154.8,
          height: 128.5,
          ml: "auto !important", // for some reason, Stack's margin will overwrite these
          mr: "auto !important",
          display: "block",
        }}
      />
      {!loginFailed &&
        (bankIdOpened ? (
          <Button
            variant="contained"
            fullWidth
            disabled
            startIcon={<CircularProgress />}
          >
            Väntar på BankID
          </Button>
        ) : (
          <Button variant="contained" fullWidth onClick={onOpenBankIdClick}>
            Öppna BankID på denna enhet
          </Button>
        ))}
      {loginFailed && <Typography variant="body1">{userMessage}</Typography>}
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 0.5 }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

export default BankIdThisDeviceLogin;

import React from "react";

import { Box, Button, Typography } from "@mui/material";
import QRCode from "qrcode";

import BankIdVectorRgb from "shared/images/BankIdVectorRgb";

interface BankIdMobileLoginQrProps {
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  loginFailed: boolean;
  qrCode: string | null;
  userMessage: string;
}

const BankIdMobileLogin: React.FC<BankIdMobileLoginQrProps> = ({
  onBackClick,
  loginFailed,
  qrCode,
  userMessage,
}) => {
  const qrCodeCanvasRef = React.useRef(null);

  React.useEffect(() => {
    if (qrCode === null || qrCodeCanvasRef?.current === null) {
      return;
    }
    QRCode.toCanvas(qrCodeCanvasRef.current, qrCode, {
      width: 212,
    });
  }, [qrCode, qrCodeCanvasRef]);

  return (
    <>
      <BankIdVectorRgb
        sx={{
          width: 154.8,
          height: 128.5,
          ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
          mr: "auto !important",
          display: "block",
        }}
      />
      {loginFailed ? (
        <Typography variant="body1">{userMessage}</Typography>
      ) : (
        <Box
          component="canvas"
          id="qrcode"
          sx={{
            minHeight: 212,
            minWidth: 212,
            width: 212,
            height: 212,
            ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
            mr: "auto !important",
            display: "block",
          }}
          ref={qrCodeCanvasRef}
          aria-label={"QR-kod för inloggning med BankID"}
        />
      )}
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 0.5 }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

export default BankIdMobileLogin;
